<script setup>
import Button from '@/Components/Button/Button.vue';
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
import Array from '@/Components/Input/Array.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    location: Object,
    paymentPeriodOptions: Object,
});

const form = useForm({
    cost_center: '',
    ...props.location,
});

const sendForm = () => {
    form.put(route('staff.locations.update', { location: props.location.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>
<template>
    <FormSingleSettingBar :title="$t('Contact Details')">
        <FormKit type="form" id="locationUpdateForm" @submit="sendForm" :actions="false">
            <div class="grid gap-5">
                <FormKit
                    type="text"
                    name="name"
                    :label="$t('Name')"
                    v-model="form.name"
                    :errors="form.errors.name"
                    :value="form.name"
                    validate="required"
                />
                <FormKit
                    type="text"
                    name="phone_number"
                    :label="$t('Phone Number')"
                    v-model="form.phone_number"
                    :errors="form.errors.phone_number"
                    :value="form.phone_number"
                    validate="required"
                />

                <FormKit
                    type="text"
                    name="administration_email"
                    :label="$t('Administration email')"
                    v-model="form.administration_email"
                    :errors="form.errors.administration_email"
                    :value="form.administration_email"
                    :help="$t('For multiple email addresses, separate them with a comma.')"
                />

                <div class="flex justify-end">
                    <Button
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                        icon="/icon/done-white.svg"
                        :text="$t('Save')"
                    />
                </div>
            </div>
        </FormKit>
    </FormSingleSettingBar>
</template>
